/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function () {
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('external').attr('target', '_blank');



                //*Mobile Menu*//
                $(".c-header__toggle").click(function (e) {
                    e.preventDefault();

                    $('.m-menu').animate({ height: 'toggle' });
                });

                // for the mobile animation

                $(".c-header__toggle").click(function () {
                    $(this).toggleClass("is-active");
                });


                // logo on scroll
                var elWrap = $('.c-header');

                if (viewportWidth <= 1024) {
                    $(window).scroll(function () {
                        var scrollX = $(this).scrollTop();

                        if (scrollX >= 120) {
                            $(elWrap).addClass('is-scrolling');
                        } else {
                            $(elWrap).removeClass('is-scrolling');
                        }
                    });
                }





                //   Secondary Hero
                var hero = $('.s_hero-back').data('desktop'),
                    heromobile = $('.s_hero-back').data('mobile');
                if (viewportWidth < 400) {
                    $('.s_hero-back').css('background-image', 'url(' + heromobile + ')');
                } else {
                    $('.s_hero-back').css('background-image', 'url(' + hero + ')');
                }

                //   Aemnities Image
                var amenities = $('.image-back').data('desktop'),
                    amenitiesmobile = $('.image-back').data('mobile');
                if (viewportWidth < 460) {
                    $('.image-back').css('background-image', 'url(' + amenitiesmobile + ')');
                } else {
                    $('.image-back').css('background-image', 'url(' + amenities + ')');
                }



                if ($('.js-carousel-gallery').length) {
                    $('.js-carousel-gallery').slick({
                        arrows: true,
                        asNavFor: '.js-carousel-nav',
                        dots: false,
                        fade: true,
                        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-right"></i></button>',
                        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-left"></i></button>',
                        rows: 0,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    });
                }

                if ($('.js-carousel-nav').length) {
                    $('.js-carousel-nav').slick({
                        arrows: false,
                        asNavFor: '.js-carousel-gallery',
                        autoplay: true,
                        dots: false,
                        focusOnSelect: true,
                        rows: 0,
                        slidesToShow: 3,
                        slidesToScroll: 1
                    });
                }


                if ($('.gform_validation_error').length) {
                    $('body').addClass('has-error');
                }

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
